<template>
    <div class="navmb">
        <div class="flex flexa">
            <span class="">公司文档管理</span>
            <UserCompanyList @query="query">
                <template #="{ list, loading }">
                    <el-select-v2 v-if="tabval == 1" v-model="documentReview.param.companyId" placeholder="选择公司" class="w300 marl20"
                        filterable clearable :props="{ label: 'name', value: 'id' }" :loading="loading" :options="list"
                        @change="queryDocumentReview()" />
                    <el-select-v2 v-else-if="tabval == 2" v-model="companyDocument.param.companyId" placeholder="选择公司" class="w300 marl20"
                        filterable clearable :props="{ label: 'name', value: 'id' }" :loading="loading" :options="list"
                        @change="getCompanyDocument()" />
                </template>
            </UserCompanyList>
        </div>
    </div>
    <div class="bgf navmb" style="margin-top: 60px;height: 40px">
        <el-tabs v-model="tabval" style="margin-bottom: 0px;" @tab-change="getData">
            <el-tab-pane label="文档审核" name="1"></el-tab-pane>
            <el-tab-pane label="文档分类管理" name="2"></el-tab-pane>
        </el-tabs>
    </div>
    <div class="bgf padd20 flex" style="margin-top: 140px;">
        <div class="form-item-g-w374">
            <el-row type="flex" v-if="tabval == 1">
                <el-col :span="8">
                    <el-select size="large" v-model="documentReview.param_form.searchKey" placeholder="选择">
                        <el-option label="文档标题" value="docTitle" />
                        <el-option label="文档内容" value="docContent" />
                    </el-select>
                </el-col>
                <el-col :span="16">
                    <el-input size="large" v-model="documentReview.param_form[documentReview.param_form.searchKey]"
                        clearable placeholder="搜索关键字" @clear="queryDocumentReview">
                        <template #suffix>
                            <el-icon color="#FF6A00" class="el-input__icon astyle" @click="queryDocumentReview">
                                <Search />
                            </el-icon>
                        </template>
                    </el-input>
                </el-col>
            </el-row>
            <el-button v-else-if="tabval == 2" type="primary" class="w115 "
                @click="companyDocument.visible = true,companyDocument.form.companyId=companyDocument.param.companyId,companyDocument.form.parentId=0">添加分类</el-button>
        </div>
        <el-select  v-if="tabval == 1" @change="queryDocumentReview()" style="width:200px;margin-left:20px" size="large" v-model="documentReview.param_form.stasus" placeholder="选择">
            <el-option label="全部" :value="-1" />
            <el-option label="审核中" :value="1" />
            <el-option label="已拒绝" :value="2" />
            <el-option label="已通过" :value="3" />
        </el-select>
    </div>
    <div class="bgf mart20" v-if="tabval == 2">
        <el-table :data="companyDocument.list" row-key="id"  style="width: 100%" :tree-props="{ children: 'list' }" v-loading="companyDocument.loading">
            <el-table-column fixed label="分类名称" prop="categoryTitle" class-name="padl20 padr20"
                :show-overflow-tooltip="{ effect: 'light' }" min-width="234">
            </el-table-column>
            <el-table-column min-width="140" prop="docCount" label="分类下文章" :show-overflow-tooltip="{ effect: 'light' }">
            </el-table-column>
            <el-table-column min-width="140" prop="createTime" label="添加时间"
                :show-overflow-tooltip="{ effect: 'light' }">
            </el-table-column>
            <el-table-column min-width="140" prop="createTime" align="center" label="操作"
                :show-overflow-tooltip="{ effect: 'light' }">
                <template #="{ row }">
                    <el-button v-if="row.parentId==0" @click="companyDocument.visible=true,companyDocument.form.parentId=row.id,companyDocument.form.companyId=row.companyId" text type="primary">添加二级分类</el-button>
                    <el-divider v-if="row.parentId==0" direction="vertical" />
                    <el-button @click="toAdd(row)" text type="primary">编辑</el-button>
                    <el-divider direction="vertical" />
                    <el-button :loading="row.deleteLoading ?? false" @click="deleteRow(row)" text
                        type="danger">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab w100 borbox">
            <p class="font10 cola4">共 {{ companyDocument.total }} 条</p>
            <el-pagination v-model:currentPage="companyDocument.param.pageNumber"
                @update:page-size="companyDocument.param.pageSize = $event; getCompanyDocument(1)"
                @update:current-page="getCompanyDocument" :page-sizes="[10, 15, 20, 25]"
                :page-size="companyDocument.param.pageSize" layout=" sizes, prev , jumper , next"
                :total="companyDocument.total">
            </el-pagination>
        </div>
    </div>
    <div class="bgf mart20" v-else-if="tabval == 1">
        <el-table :data="documentReview.list" style="width: 100%" v-loading="documentReview.loading">
            <el-table-column fixed label="文档标题" class-name="padl20 padr20" :show-overflow-tooltip="{ effect: 'light' }"
                min-width="234">
                <template #="{ row }">
                    <div class="flex padr14">
                        <router-link class="bllk col279 block text1" :to="{
                            name: 'knowledgeInfo',
                            params: {
                                id: row.id
                            },
                            state: {
                                audit: 1
                            }
                        }" :state="{ a:43534 }">{{ row.docTitle
                            }}</router-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="140" prop="companyCategorName" label="所属公司文档分类"
                :show-overflow-tooltip="{ effect: 'light' }">
            </el-table-column>
            <el-table-column min-width="140" prop="isSystem" label="是否同步到平台">
                <template #="{ row }">
                    <span>{{ isSystem.get(row.isSystem) }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="140" prop="userName" label="文档Owenr">
            </el-table-column>
            <el-table-column min-width="140" prop="userNo" label="文档OwenrID">
            </el-table-column>
            <el-table-column min-width="140" prop="createTime" label="状态">
                <template #default="scope">
                    {{ {1:'审核中',2:'已拒绝',3:'已通过'}[scope.row.stasus] }}
                </template>
            </el-table-column>
            <el-table-column min-width="140" prop="createTime" label="发布时间">
            </el-table-column>
            <el-table-column fixed="right" min-width="280" align="center" label="操作">
                <template #="{ row }">
                    <slot v-if="row.stasus==1">
                        <el-button :disabled="row.disable ?? false" @click="checkDoc(row, 3)" text
                            type="primary">通过</el-button>
                        <el-divider direction="vertical" />
                        <el-button :disabled="row.disable ?? false"
                            @click="statusData.show = true; statusData.form = { docId: row.id, stasus: 2, reason: '' }" text
                            type="danger">拒绝</el-button>
                            <el-divider direction="vertical" />
                    </slot>
                        <el-button @click="toAdds(row)" text type="primary">编辑</el-button>
                        <el-divider direction="vertical" />
                        <el-button :loading="row.deleteLoading??false" @click="deleteRows(row)" text type="danger">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab w100 borbox">
            <p class="font10 cola4">共 {{ documentReview.total }} 条</p>
            <el-pagination v-model:currentPage="documentReview.param.pageNumber"
                @update:page-size="documentReview.param.pageSize = $event; getDocumentReview(1)"
                @update:current-page="getDocumentReview" :page-sizes="[10, 15, 20, 25]"
                :page-size="documentReview.param.pageSize" layout=" sizes, prev , jumper , next"
                :total="documentReview.total">
            </el-pagination>
        </div>
    </div>
    <el-dialog :close-on-click-modal="false" v-model="companyDocument.visible" center title="新增分类" width="577px" align-center
        @closed="(companyDocument.form = {}) && $refs.submitForm.resetFields()">
        <el-form v-loading="companyDocument.fullscreenLoading" :model="companyDocument.form" ref="submitForm"
            label-width="140px" :rules="companyDocument.rules">
            <el-form-item label="分类名称：" prop="categoryTitle">
                <el-input v-model="companyDocument.form.categoryTitle" placeholder="请输入" class="w242" />
            </el-form-item>
            <el-form-item label="公司名称：" prop="companyId">
                <el-select v-model="companyDocument.form.companyId" class="w242" placeholder="选择公司">
                    <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="companyDocument.submitLoading" @click="submit">确定</el-button>
            <el-button @click="companyDocument.visible = false">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" v-model="statusData.show" title="拒绝知识文档" width="520px">
        <el-form :model="statusData.form" ref="submitAudit" label-width="auto" :rules="statusData.rules">
            <el-form-item label="请输入拒绝理由" prop="reason">
                <el-input type="textarea" v-model="statusData.form.reason" placeholder="请输入拒绝理由" />
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button :loading="statusData.submitLoading" type="primary" @click="submit_audits()">确定</el-button>
            <el-button @click="statusData.show = false">取消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { Search } from '@element-plus/icons-vue'
import UserCompanyList from '@/components/data/UserCompanyLists'
import store from '@/store'
export default {
    setup() {
        return {
            isSystem: new Map([
                [0, '否'],
                [1, '是']
            ])
        }
    },
    components: {
        Search,
        UserCompanyList
    },
    data() {
        return {
            statusData: {
                show: false, form: {}, submitLoading: false,
                rules: {
                    reason: [{ required: true, message: '请输入拒绝理由', trigger: 'blur' }],
                },
            },
            tabval: '1',
            companyList: [],
            documentReview: {
                param_form: {
                    searchKey: 'docTitle',stasus:-1
                },
                param: {
                    pageNumber: 1,
                    pageSize: 10
                },
                list: [],
                total: 0,
            },
            companyDocument: {
                visible: false,
                loading: false,
                param: {
                    type: '1',
                    pageNumber: 1,
                    pageSize: 10
                },
                list: [],
                total: 0,
                submitLoading: false,
                fullscreenLoading: false,
                form: {

                },
                rules: {
                    companyId: [{ required: true, message: '请选择公司', trigger: 'change' }],
                    categoryTitle: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
                }
            }
        }
    },
    methods: {
        async deleteRows(row) {
            try {
                await this.$confirm(
                    "确认删除吗",
                    "信息",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                )
                row.deleteLoading = true
                const result = await this.$api.document.removeDoc({
                    id: row.id,
                })
                if (result) {
                    this.getList(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.deleteLoading = false
            }
        },
        toAdds(row) {
            this.$router.push({
                path: '/document/creates',
                state: {
                    id: row.id,
                    docTitle: row.docTitle,
                    userCategoryId: row.userCategoryId,
                    companyCategoryId: row.companyCategoryId,
                }
            })
        },
        query(e) {
            if (e[0]) {
                this.documentReview.param.companyId = e[0].id
                this.companyDocument.param.companyId = e[0].id
                if (this.tabval == 1) {
                    this.queryDocumentReview()
                } else if (this.tabval == 2) {
                    this.getCompanyDocument()
                }
            }
        },
        async submit_audits(validate = this.$refs['submitAudit'].validate) {
            try {
                await validate()
                this.statusData.submitLoading = true
                console.log(this.statusData.form)
                const result = await this.$api.document.checkDoc(this.statusData.form)
                if (result) {
                    this.$message.success('操作成功')
                    this.statusData.show = false
                    this.getData()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.statusData.submitLoading = false
            }
        },
        async checkDoc(row, stasus) {
            try {
                row.disable = true
                const result = await this.$api.document.checkDoc({
                    docId: row.id,
                    stasus
                })
                if (result) {
                    this.$message.success(result.message)
                    this.getDocumentReview(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.disable = false
            }
        },
        toAdd(row) {
            this.companyDocument.form = Object.assign({}, row)
            this.companyDocument.visible = true
        },
        async deleteRow(row) {
            try {
                await this.$confirm(
                    "确认删除吗",
                    "信息",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                )
                row.deleteLoading = true
                const result = await this.$api.document.deleteACompanyDocumentCategory({
                    id: row.id,
                })
                if (result) {
                    this.getCompanyDocument(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.deleteLoading = false
            }
        },
        async submit() {
            try {
                this.companyDocument.submitLoading = this.companyDocument.fullscreenLoading = true
                await this.$refs['submitForm'].validate()
                const result = await this.$api.document.addCompanyDocumentCategories(this.companyDocument.form)
                if (result) {
                    console.log(result)
                    this.companyDocument.visible = false
                    this.$message.success('操作成功')
                    this.getCompanyDocument(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.companyDocument.submitLoading = this.companyDocument.fullscreenLoading = false
            }
        },
        getData() {
            if (this.tabval == 1) {
                return this.getDocumentReview(1)
            }
            return this.getCompanyDocument()
        },
        async userCompanyLists() {
            try {
                const result = await this.$api.document.userCompanyLists()
                this.companyList = result
            } catch (e) {
                return console.log(e)
            } finally {

            }
        },
        queryDocumentReview() {
            const { searchKey } = this.documentReview.param_form
            this.documentReview.param[searchKey] = this.documentReview.param_form[searchKey]
            this.documentReview.param.stasus = this.documentReview.param_form.stasus==-1?'':this.documentReview.param_form.stasus
            console.log(this.documentReview, 'rrr')
            this.getDocumentReview(1)
        },
        async getDocumentReview(pageNumber) {
            try {
                pageNumber && (this.documentReview.param.pageNumber = pageNumber)
                this.documentReview.loading = true
                const { records, total } = await this.$api.document.documentReview(this.documentReview.param)
                console.log('列表',records)
                this.documentReview.list = records
                this.documentReview.total = total
            } catch (e) {
                return console.log(e)
            } finally {
                this.documentReview.loading = false
            }
        },
        async getCompanyDocument(pageNumber) {
            try {
                pageNumber && (this.companyDocument.param.pageNumber = pageNumber)
                this.companyDocument.loading = true
                const { records, total } = await this.$api.document.companyDocument(this.companyDocument.param)
                console.log('records',records)
                this.companyDocument.list = records
                this.companyDocument.total = total
            } catch (e) {
                return console.log(e)
            } finally {
                this.companyDocument.loading = false
            }
        },
    },
    mounted() {
        // this.getData()
        this.userCompanyLists()
    },
    beforeRouteEnter(to, from, next) {
        next(store.state?.userinfo?.isCoe == 1)
    }
}
</script>
<style scoped lang="scss">
.form-item-g-w374 {
    width: 374px;

    .el-select {
        width: calc(100% + 1px)
    }
}
::v-deep .el-tabs__header {
    margin-bottom: 0
}
:deep(.el-tabs__nav-wrap::after) {
    position: static !important;
  }
  
</style>